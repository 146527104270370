<template>
    <div :class="['main-layout', { 'dark-mode': isDarkMode }]">
        <header class="app-header">
            <div class="app-name">Dftar</div>
            <div class="header-controls">
                <button @click="toggleDarkMode" class="btn">{{ isDarkMode ? 'Light Mode' : 'Dark Mode' }}</button>

                <div class="user-account">
                    <img src="https://via.placeholder.com/40" alt="User Avatar" class="user-avatar" />
                </div>
            </div>
        </header>

        <div class="content-layout">
            <!-- Left Side: Document Versions -->
            <div class="left-side">
                <ul class="version-list">
                    <li class="version-item">Version 1 - Jan 10, 2024</li>
                    <li class="version-item">Version 2 - Jan 12, 2024</li>
                    <li class="version-item">Version 3 - Jan 15, 2024</li>
                </ul>

                <!-- Control Buttons -->
                <div class="control-buttons">
                    <button @click="refineText" class="btn refine-btn">Refine</button>
                    <button @click="exportAsWord" class="btn export-btn">Export</button>
                </div>
            </div>

            <!-- Editor Container -->
            <div class="editor-container">
                <div class="prompt-input">
                    <input v-model="userPrompt" type="text" placeholder="Enter prompt to generate content"
                        class="prompt-field" />
                    <button @click="generateContent" class="btn generate-btn">Generate Content</button>
                </div>

                <div class="text-editor">
                    <div id="compare" style="height: 0px; visibility: hidden;"></div>
                    <quill-editor v-model="editorContent" :options="editorOptions" @ready="onEditorReady" />
                </div>
            </div>

            <div class="chat-container">
                <div class="chat-history" ref="chatHistory">
                    <div v-for="(message, index) in messages" :key="index" :class="[message.role, 'message-bubble']">
                        <p>{{ message.content }}</p>
                    </div>
                </div>
                <div class="chat-input">
                    <input v-model="chatPrompt" type="text" placeholder="Send a message..." class="chat-field"
                        @keyup.enter="sendChatPrompt" />
                    <button @click="sendChatPrompt" class="btn send-btn">Send</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, nextTick } from 'vue'
import { quillEditor } from 'vue3-quill'
import QuillMarkdown from 'quilljs-markdown';
import { marked } from 'marked';
import { diffWords } from 'diff';
import Mergely from 'mergely';
import 'quill/dist/quill.snow.css'
import './TextEditor.css'
import './TextEditor.dark.css'

const isDarkMode = ref(true)
const toggleDarkMode = () => {
    isDarkMode.value = !isDarkMode.value
}

const editorContent = ref('')
const userPrompt = ref('')
let quillInstance = null
let mergelyInstance = null

const editorOptions = {
    theme: 'bubble',
    modules: {
        toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline'],
            ['image', 'code-block'],
        ],
    },
}

const onEditorReady = (quill) => {
    quillInstance = quill
    new QuillMarkdown(quill)
    mergelyInstance = new Mergely('#compare', {
        lhs: '',
        rhs: '',
    });
}

const setMarkdownContent = (content) => {
    const htmlContent = marked(content);
    if (quillInstance)
        quillInstance.clipboard.dangerouslyPasteHTML(htmlContent);
}

const generateContent = async () => {
    if (!userPrompt.value.trim()) {
        console.log('Please enter a valid prompt.')
        return
    }

    try {
        const response = await fetch("http://46.101.157.12:8000/generateContent", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ prompt: userPrompt.value.trim() })
        });

        if (response.ok) {
            const data = await response.json();
            setMarkdownContent(data.content)
            mergelyInstance.lhs(data.content)
        } else {
            console.error("Error generating content:", response.statusText);
        }
    } catch (error) {
        console.error("Fetch error:", error);
    }
}

const refineText = async () => {
    try {
        const response = await fetch("http://46.101.157.12:8000/refineContent", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ text: quillInstance.getText().trim() })
        });

        if (response.ok) {
            const data = await response.json()
            const diff = calculateDiff(quillInstance.getText(), data.refined_content)
            setMarkdownContent(diff.diffHTML)
            console.log(mergelyInstance)
            mergelyInstance.rhs(data.refined_content)
        } else {
            console.error("Error generating content:", response.statusText);
        }
    } catch (error) {
        console.error("Fetch error:", error);
    }
}

const calculateDiff = (originalText, newText) => {
    const diff = diffWords(originalText, newText);
    let changes = [];

    const diffHTML = diff.map(part => {
        const color = part.added ? 'green' : part.removed ? 'red' : 'black';

        if (part.added) {
            changes.push({ type: 'added', value: part.value });
        } else if (part.removed) {
            changes.push({ type: 'removed', value: part.value });
        }

        return `<span style="color: ${color};">${part.value}</span>`;
    }).join('');

    return { diffHTML, changes };
};


const exportAsWord = () => {
    console.log('Exporting content as Word...')
}


const chatPrompt = ref('')
const messages = ref([
    { role: 'assistant', content: 'Hello! How can I assist you today?' },
])

const sendChatPrompt = async () => {
    if (chatPrompt.value.trim()) {
        const userMessage = { role: 'user', content: chatPrompt.value.trim() }
        messages.value.push(userMessage)

        const aiResponse = { role: 'assistant', content: `AI Response to "${chatPrompt.value}"` }
        setTimeout(() => {
            messages.value.push(aiResponse)
            scrollToBottom()
        }, 1000)

        chatPrompt.value = ''
        await nextTick()
        scrollToBottom()
    }
}

const scrollToBottom = () => {
    const chatHistory = document.querySelector('.chat-history')
    chatHistory.scrollTop = chatHistory.scrollHeight
}
</script>
